// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@import "lightgallery/scss/lightgallery";
@import "lightgallery/scss/lg-thumbnail";
@import "lightgallery/scss/lg-video";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$client-primary: mat.define-palette(mat.$green-palette);
$client-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $client-warn: mat.define-palette(mat.$red-palette);

$client-typography: mat.define-typography-config(
  $font-family: "Cairo"
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$client-theme: mat.define-light-theme(
  (
    color: (
      primary: $client-primary,
      accent: $client-accent,
      // warn: $client-warn,
    ),
    typography: $client-typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($client-theme);

/* You can add global styles to this file, and also import other style files */

// html,
// body {
//   height: 100%;
// }
// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }

.cms-content img {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

#goals li {
  margin-bottom: 1rem;
}

#goals ul,
ol {
  padding-inline-start: 1rem;
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-down {
  -webkit-animation-name: fade-in-down;
  animation-name: fade-in-down;
  animation-duration: 1s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
  animation-duration: 1s;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}

.zoom-in {
  -webkit-animation-name: zoom-in;
  animation-name: zoom-in;
  animation-timing-function: ease-in-out;
}

.fast {
  animation-duration: 200ms;
}

.grecaptcha-badge {
  visibility: hidden;
}

.masonry {
  display: grid;
  grid-auto-flow: column;
}

.cursor-pointer {
  cursor: pointer;
}

.mb-reset p {
  margin-bottom: 0;
}

.spinner {
  -webkit-animation: frames 1s infinite linear;
  animation: frames 1s infinite linear;
  background: transparent;
  border: 0.7vw solid #fff;
  border-radius: 100%;
  border-top-color: #df691a;
  width: 6vw;
  height: 6vw;
  opacity: 0.6;
  padding: 0;
  // position: absolute;
  // z-index: 999;
}

@keyframes frames {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.newsletter-popover {
  font-size: 14px;

  &.popover {
    --bs-popover-font-size: 0.875em;
    --bs-popover-border-radius: 0.5em;
    --bs-popover-inner-border-radius: calc(0.5em - 1px);
    --bs-popover-box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.15);
    --bs-popover-header-padding-x: 1em;
    --bs-popover-header-padding-y: 0.5em;
    --bs-popover-header-font-size: 1em;
    --bs-popover-body-padding-x: 1em;
    --bs-popover-body-padding-y: 1em;
    --bs-popover-arrow-width: 1em;
    --bs-popover-arrow-height: 0.5em;
  }

  &.success {
    background-color: #00a651;
    .popover-body {
      color: white !important;
      font-weight: bold;
    }
    .popover-arrow:after {
      border-top-color: #00a651;
    }
  }

  &.error {
    background-color: #e74c3c;
    .popover-body {
      color: white !important;
      font-weight: bold;
    }
    .popover-arrow:after {
      border-top-color: #e74c3c;
    }
  }
}

// .section-title {
//   background:
//     linear-gradient(-45deg, rgb(var(--bs-secondary-rgb)) 40%, #fff 0 60%, rgb(var(--bs-secondary-rgb)) 0) right/300%
//       100% no-repeat,
//     linear-gradient(
//         -45deg,
//         rgba(var(--bs-secondary-rgb), 0) 40%,
//         rgb(var(--bs-secondary-rgb)) 0 60%,
//         rgba(var(--bs-secondary-rgb), 0) 0
//       )
//       right/300% 100% no-repeat;
//   -webkit-background-clip: text, padding-box;
//   -moz-background-clip: text, padding-box;
//   background-clip: text, padding-box;
//   transition: 0.8s;
//   color: #0000 !important;
//   cursor: pointer;
//   padding-left: 20px;
//   padding-right: 20px;
//   line-height: inherit;
// }

// .section-title:hover {
//   background-position: left;
// }

html {
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-transition: -webkit-filter 500ms ease;
  -moz-transition: -moz-filter 500ms ease;
  transition: filter 500ms ease;
}

html.grayscale {
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  filter: grayscale(1);
}

.navbar-font-override {
  font-size: 14px;

  .btn {
    --bs-btn-font-size: 1em;
    --bs-btn-font-weight: 500;
    --bs-btn-padding-x: 0.75em;
    --bs-btn-padding-y: 0.375em;
    --bs-btn-border-radius: 0.375em;
  }

  .py-2 {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
  }

  .gap-2 {
    gap: 0.5em !important;
  }

  .mx-2 {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }

  .container {
    --bs-gutter-x: 1.5em;
    --bs-gutter-y: 0;
  }

  .px-lg-5 {
    padding-left: 3em !important;
    padding-right: 3em !important;
  }

  .mx-1 {
    margin-left: 0.25em !important;
    margin-right: 0.25em !important;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .dropdown-toggle {
    gap: 0.5em;
  }

  .navbar-toggler {
    font-size: 1.25em;
  }

  .offcanvas-header {
    padding: 1em 1em;
  }

  .py-3 {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }

  .ps-2 {
    padding-right: 0.5em !important;
  }

  .dropdown-menu {
    --bs-dropdown-min-width: 10em;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5em;
    --bs-dropdown-spacer: 0.125em;
    --bs-dropdown-font-size: 1em;
    --bs-dropdown-border-radius: 0.375em;
    --bs-dropdown-border-width: 1px;
    --bs-dropdown-inner-border-radius: calc(0.375em - 1px);
    --bs-dropdown-divider-margin-y: 0.5em;
    --bs-dropdown-box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.15);
    --bs-dropdown-item-padding-x: 1em;
    --bs-dropdown-item-padding-y: 0.25em;
    --bs-dropdown-header-padding-x: 1em;
    --bs-dropdown-header-padding-y: 0.5em;
  }

  .navbar-megamenu-url {
    padding-inline: 1em;
    padding-block: 0.5em;
  }
}

.footer-font-override {
  font-size: 14px;

  .pt-5 {
    padding-top: 3em !important;
  }

  .py-3 {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }

  .container {
    --bs-gutter-x: 1.5em;
    --bs-gutter-y: 0;
  }

  .ps-2 {
    padding-right: 0.5em !important;
  }

  h6 {
    margin-bottom: 0.5em;
  }

  .me-3 {
    margin-left: 1em !important;
  }

  .row {
    --bs-gutter-x: 1.5em;
  }

  h5 {
    font-size: 1.25em;
    margin-bottom: 0.5em;
  }

  .form-control {
    padding: 0.375em 0.75em;
    font-size: 1em;
    border-radius: 0.375em;
  }

  .input-group-text {
    padding: 0.375em 0.75em;
    font-size: 1em;
    border-radius: 0.375em;
  }

  .px-4 {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
  }

  .btn {
    --bs-btn-font-size: 1em;
    --bs-btn-font-weight: 500;
    --bs-btn-padding-x: 0.75em;
    --bs-btn-padding-y: 0.375em;
    --bs-btn-border-radius: 0.375em;
  }

  .rounded-end {
    border-start-end-radius: 0.375em !important;
    border-end-end-radius: 0.375em !important;
  }

  .mt-4 {
    margin-top: 1.5em !important;
  }

  .gap-4 {
    gap: 1.5em !important;
  }

  .social-media-links a {
    font-size: 1.3em;
  }

  p {
    margin-bottom: 1em;
  }
}

.landing-swiper-controls {
  .navigation-btn {
    color: var(--bs-secondary);
  }

  display: flex;
  flex-direction: row-reverse;
}

:dir(ltr) .landing-swiper-controls {
  flex-direction: row;
}
